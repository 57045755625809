<script>
import feather from 'feather-icons';
import moment from 'moment';

export default {
	props: ['event'],
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {
		formatDateTime(date) {
			return moment(date).format('DD.MM.YYYY, HH:mm') + ' Uhr';
		},
		formatDate(date) {
			return moment(date).format('DD.MM.YYYY');
		},
		formatDay(date) {
			return moment(date).format('DD');
		},
		formatMonth(date) {
			return moment(date).format('MMM');
		},
		getLocationUrl(location) {
			return "http://maps.google.com/?q=" + location;
		}
	}
};
</script>

<template>
	<div class="w-full flex justify-center items-center border border-gray-200 rounded-lg shadow flex-row">

		<div class="sm:mx-10 mx-6 my-4">
			<div v-if="event.date != null">
				<p class="font-general-semibold text-ternary-dark dark:text-ternary-light font-semibold text-xl">{{
					formatMonth(event.date).toUpperCase() }}</p>
				<p class="font-general-semibold text-ternary-dark dark:text-ternary-light font-semibold text-4xl">{{
					formatDay(event.date) }}</p>
			</div>
			<div v-else>
				<p class="font-general-semibold text-ternary-dark dark:text-ternary-light font-semibold text-4xl">TBD</p>
			</div>
		</div>

		<div class="w-[2px] h-3/4 bg-gray-200" />

		<div class="sm:mx-10 mx-6 my-4 w-full text-left">
			<p class="font-general-semibold text-ternary-dark dark:text-ternary-light font-semibold text-xl">{{ event.name
			}}</p>

			<p class="font-general-regular text-ternary-dark dark:text-ternary-light">{{ event.slogan }}</p>

			<div class="mt-4 flex">
				<div v-if="event.location !== undefined && event.location !== ''" class="flex">
					<i data-feather="map-pin" class="w-5 h-5 mr-2"></i>
					<p v-if="event.location === 'Privat'"
						class="font-general-regular text-ternary-dark dark:text-ternary-light"><i>{{
							event.location }}</i></p>
					<a v-else :href="getLocationUrl(event.location)" class="flex hover:underline cursor-pointer" target="_blank"><p class="font-general-regular text-ternary-dark dark:text-ternary-light">{{
						event.location }}</p></a>
				</div>
				<div v-else class="flex">
					<i data-feather="map-pin" class="w-5 h-5 mr-2"></i>
					<p class="font-general-regular text-ternary-dark dark:text-ternary-light"><i>In Klärung</i></p>
				</div>
			</div>

			<div class="mt-2 flex">
				<i data-feather="clock" class="w-5 h-5 mr-2"></i>

				<div v-if="event.date != null">
					<p v-if="event.timeundefined !== undefined && event.timeundefined === true"
						class="font-general-regular text-ternary-dark dark:text-ternary-light">{{
							formatDate(event.date) }}, <i>Uhrzeit in Klärung</i></p>
					<p v-else class="font-general-regular text-ternary-dark dark:text-ternary-light">{{
						formatDateTime(event.date) }}</p>
				</div>
				<p v-else class="font-general-regular text-ternary-dark dark:text-ternary-light"><i>In Klärung</i></p>
			</div>

		</div>

	</div>
</template>
<style lang="scss" scoped></style>
