<script>
import AppBanner from '@/components/shared/AppBanner';
import About from '@/components/about/About';
import Events from '@/components/events/Events';
import Contact from '@/components/contact/Contact';

export default {
	name: 'Home',
	components: {
		AppBanner,
		About,
		Events,
		Contact,
	},
};
</script>

<template>
	<div class="container mx-auto pb-36">
		<AppBanner />

		<About />

		<Events/>

		<Contact />

		<!-- Load more projects button -->
		<!-- <div class="mt-10 sm:mt-20 flex justify-center">
			<router-link
				to="/projects"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
				aria-label="More Projects"
			>
				<Button title="More Projects" />
			</router-link>
		</div> -->
	</div>
</template>

<style scoped></style>
