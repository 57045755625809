<script>
import ContactForm from '@/components/contact/ContactForm';
import ContactDetails from '@/components/contact/ContactDetails';

export default {
	components: {
		ContactForm,
		ContactDetails,
	},
	data: () => {
		return {
			contacts: [
				{
					id: 1,
					name: 'mail@revived-music.de',
					icon: 'mail',
				},
				{
					id: 2,
					name: 'Hockenheim, Deutschland',
					icon: 'map-pin',
				},
				// {
				// 	id: 3,
				// 	name: '+49 171 0000000',
				// 	icon: 'phone',
				// },
			],
		};
	},
};
</script>

<template>
	<div class="text-center pt-24">
		<p class="font-general-semibold text-3xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light">
			Kontakt
		</p>
	</div>

	<div class="mt-10 sm:mt-10 flex justify-center">
		<p class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg text-justify">
			Wenn ihr euch für eine begeisternde und elektrisierende Reise durch die Musikgeschichte interessiert,
			kontaktiert uns gerne über unsere Social-Media-Kanäle oder per Mail unter <a class="hover:underline cursor-pointer" href="mailto:mail@revived-music.de">mail@revived-music.de</a>. Wir blicken mit
			Begeisterung darauf, eure Veranstaltung mit unserer Musik zu bereichern und freuen uns schon auf unsere
			kommenden Auftritte – seid dabei, wenn <b>REVIVED</b> die Bühne rockt und das Publikum in seinen Bann zieht!
		</p>
	</div>

	<div class="pt-12 container mx-auto flex flex-col-reverse md:flex-row py-5 md:py-10">
		<ContactForm />
		<ContactDetails :contacts="contacts" />
	</div></template>
