<script>
import feather from 'feather-icons';
import { socialLinks } from '../../data/socialLinks';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
			socials: socialLinks
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section class="w-full h-screen">
		<div class="absolute top-0 left-0 w-full h-full z-20 flex flex-col justify-center">
			<img src="@/assets/images/Logo_3D.svg" class="w-8/12 object-contain mx-auto" alt="REVIVED Logo" />

			<ul class="flex gap-4 sm:gap-8 justify-center mt-12 sm:mt-24">
				<a v-for="social in socials" :key="social.id" :href="social.url" target="__blank"
					class="hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg dark:bg-ternary-dark hover:bg-gray-100 p-4 duration-500">
					<i :data-feather="social.icon" class="w-8 sm:w-12 h-8 sm:h-12"></i>
				</a>
			</ul>

			<p class="text-xl sm:text-3xl mt-12 sm:mt-24 w-10/12 sm:w-8/12 mx-auto">
				Wir nehmen euch mit auf eine mitreißende, leidenschaftliche und energiegeladene Reise durch die
				Musikgeschichte, die nicht nur zum Tanzen, Singen und Feiern einlädt, sondern auch Herzen höherschlagen
				lässt!
			</p>
		</div>

		<div class="absolute top-0 left-0 h-full w-full bg-white bg-opacity-60 z-10"></div>

		<video id="REVIVED-banner-video" autoplay loop muted playsinline class="absolute top-0 left-0 h-full w-full object-cover">
			<source src="@/assets/videos/REVIVED-banner.mp4" type="video/mp4">
		</video>

	</section>

	<!-- <section
		class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10"
	> -->
	<!-- Banner left contents -->
	<!-- <div class="w-full md:w-1/3 text-left">
			<h1
				class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase"
			>
				Hi, Iam Stoman
			</h1>
			<p
				class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-center sm:text-left leading-none text-gray-400"
			>
				A Full-Stack Developer & Design Enthusiast
			</p>
			<div class="flex justify-center sm:block">
				<a
					download="Stoman-Resume.pdf"
					href="/files/Stoman-Resume.pdf"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-indigo-500 text-gray-500 hover:text-white duration-500"
					aria-label="Download Resume"
				>
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"
					></i>
					<span
						class="text-sm sm:text-lg font-general-medium duration-100"
						>Download CV</span
					></a
				>
			</div>
		</div> -->

	<!-- Banner right illustration -->
	<!-- <div class="w-full md:w-2/3 text-right float-right">
			<img
				v-if="theme === 'light'"
				src="@/assets/images/developer.svg"
				alt="Developer"
			/>
			<img
				v-else
				src="@/assets/images/developer-dark.svg"
				alt="Developer"
			/>
		</div>
	</section> -->
</template>

<style scoped></style>
