<script>
export default {
	props: ['member'],
};
</script>

<template>
	<div class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark">
		<div> 
			<div class="flex flex-column">
				<img class="w-full h-56 object-cover rounded-t-xl border-none" :src="member.img" :alt="member.name"
					loading="lazy" />

			</div>
			<div class="text-center px-4 py-6 flex-1">
				<p class="font-general-semibold text-ternary-dark dark:text-ternary-light font-semibold mb-2">
					{{ member.name }}
				</p>
				<div class="font-general-regular text-ternary-dark dark:text-ternary-light">{{ member.instrument }}</div>
				<div class="font-general-regular text-ternary-dark dark:text-ternary-light">{{ member.age }}</div>
			</div>
		</div>
		<!-- <div> 
			<p class="font-general-semibold text-ternary-dark dark:text-ternary-light font-semibold mb-2">
					{{ member.name }}
				</p>
			<div class="font-general-regular text-ternary-dark dark:text-ternary-light">{{ member.description }}</div>
		</div> -->
	</div>

</template>
<style lang="scss" scoped></style>
