<template>
	<div class="w-full md:w-1/2">
		<div
			class="leading-loose max-w-xl m-4 p-7 bg-secondary-light dark:bg-secondary-dark rounded-xl shadow-xl text-left">
			<p class="font-general-medium text-primary-dark dark:text-primary-light text-2xl mb-8">
				Kontaktanfrage
			</p>
			<form @submit.prevent="submitForm" class="font-general-regular space-y-7">
				<div>
					<label for="name">Dein Name:</label>
					<input type="text" id="name" v-model="formData.name" required>
				</div>
				<div>
					<label for="email">Deine E-Mail:</label>
					<input type="email" id="email" v-model="formData.email" required>
				</div>
				<div>
					<label for="subject">Betreff:</label>
					<input type="text" id="subject" v-model="formData.subject" required>
				</div>
				<div>
					<label for="message">Nachricht:</label>
					<textarea id="message" v-model="formData.message" required></textarea>
				</div>
				<Button type="submit" title="Absenden" :disabled="isSubmitting"></Button>
			</form>
			<div v-if="showSuccess" class="popup success">Nachricht erfolgreich gesendet!</div>
			<div v-if="showError" class="popup error">Fehler beim Senden der Nachricht! Melde dich gerne direkt per Email
				bei uns: <a href="mailto:mail@revived-music.de">mail@revived-music.de</a></div>

		</div>
	</div>
</template>
  
<script>
import Button from '../reusable/Button.vue';
import emailjs from 'emailjs-com';
export default {
	components: { Button },
	data() {
		return {
			formData: {
				name: '',
				email: '',
				subject: '',
				message: ''
			},
			showSuccess: false,
			showError: false,
			isSubmitting: false
		};
	},
	methods: {
		submitForm() {
			if (this.isSubmitting) {
				return; // Verhindert, dass der Benutzer den Button mehrmals klickt, während die Anfrage gesendet wird
			}
			this.isSubmitting = true; // Deaktiviert den Button während des Sende-Vorgangs

			console.log(this.formData);
			emailjs.send('service_on7ny84', 'template_boqvci9', {
				from_name: this.formData.name,
				from_mail: this.formData.email,
				subject: this.formData.subject,
				message: this.formData.message
			}, '5L_Ndw_ro0gGLD_Zp')
				.then((response) => {
					console.log('E-Mail erfolgreich versendet', response.status, response.text);
					this.showSuccess = true;
					this.showError = false;
					setTimeout(() => {
						this.showSuccess = false;
					}, 3000); // 3000 Millisekunden = 3 Sekunden
				})
				.catch((error) => {
					console.error('Fehler beim Versenden der E-Mail', error);
					this.showSuccess = false;
					this.showError = true;
					setTimeout(() => {
						this.showError = false;
					}, 3000); // 3000 Millisekunden = 3 Sekunden
				})
				.finally(() => {
					this.isSubmitting = false; // Aktiviert den Button nach Abschluss der Anfrage
					if (this.showSuccess == true) {
						this.resetForm();
					}
				});
		},
		resetForm() {
			this.formData.name = '';
			this.formData.email = '';
			this.formData.subject = '';
			this.formData.message = '';
		}
	}
};
</script>
  
<style scoped>
input,
textarea {
	@apply w-full px-5 py-3 border border-gray-300 border-primary-dark border-opacity-50 text-primary-dark bg-ternary-light rounded-md shadow-sm
}

label {
	@apply block mb-2 text-lg text-primary-dark
}

.popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 20px;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	z-index: 9999;
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
}

.popup.success {
	color: #4caf50;
}

.popup.error {
	color: #f44336;
}

.popup.fade-out {
	opacity: 0;
	transition-delay: 0.5s;
}
</style>
  