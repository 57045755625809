<template>
	<button class="custom-button">
		<span class="icon">
			<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
			</svg>
		</span>
		{{ title }}
	</button>
</template>
  
<script>
export default {
	props: ['title']
};
</script>
  
<style lang="scss" scoped>
.custom-button {
	@apply flex items-center bg-ternary-light text-gray-700 rounded-md px-4 py-2 cursor-pointer transition duration-300 ease-in-out w-full;

	&:hover {
		@apply bg-red-400;
	}
}

.icon {
	@apply mr-2;
}
</style>
  