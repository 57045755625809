<script>
import AboutMusic from '@/components/about/AboutMusic';
import AboutMembers from '@/components/about/AboutMembers';
// import AboutLineup from '@/components/about/AboutLineup';

export default {
	components: {
		AboutMusic,
		AboutMembers,
		// AboutLineup,
	},
};
</script>

<template>
	<div class="text-center pt-24">
		<p class="font-general-semibold text-3xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light">
			Über uns
		</p>
	</div>

	<div class="mt-10 sm:mt-10 flex justify-center">
		<p class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg text-justify">
			Unser Bandname <b>REVIVED</b> steht für unsere Mission: Mit unserer leidenschaftlichen und professionellen
			Performance möchten wir jedem Zuhörer eine unvergessliche musikalische Erfahrung bieten und das Publikum in
			unseren Bann ziehen. Dabei ist &bdquo;REVIVED&ldquo; mehr als nur ein Name – er ist eine Darstellung unserer
			Leidenschaft,
			unseres Engagements und unseres Bestrebens, Musikgeschichte in einer neuen, aufregenden Art und Weise zu
			präsentieren und eine elektrisierende Atmosphäre zu erzeugen.

			<br /><br />
			Weitere Informationen und Unterlagen zu <b>REVIVED</b> als Download:
		</p>
	</div>

	<ul class="list-disc text-center items-center ml-6">
		<li>
			<p class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg text-justify">
				<a class="hover:underline cursor-pointer" href="https://www.revived-music.de/files/REVIVED-Bandinformation.pdf" target="_blank">Bandinformation</a>
			</p>
		</li>
		<li>
			<p class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg text-justify">
				<a class="hover:underline cursor-pointer" href="https://www.revived-music.de/files/REVIVED-Logos.zip" target="_blank">Logos (AI-, SVG- und
					PNG-Dateien)</a>
			</p>
		</li>
	</ul>

	<div class="pt-12">
		<AboutMusic />
	</div>

	<div class="pt-12">
		<AboutMembers />
	</div>

	<!-- <div class="pt-12">
		<AboutLineup />
	</div> -->
</template>
