// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const members = [
	{
		id: 1,
		name: 'Tobias Ernst',
		instrument: 'Gesang',
		description: 'Tobias Ernst, unser charismatischer Leadsänger, entdeckte seine Liebe zur Musik bereits in früher Kindheit und hat sich seitdem stetig weiterentwickelt. Ausgezeichnet mit renommierten Preisen wie dem Werner-Stober-Preis, fasziniert er mit seiner kraftvollen Stimme und seinem einnehmenden Bühnenauftritt.',
		age: Math.floor((Date.now() - new Date(2003, 3, 1)) / 31557600000).toString() + " Jahre",
		img: require('@/assets/images/members/tobias.jpg'),
	},
	{
		id: 2,
		name: 'Matthew Fischer',
		instrument: 'Gitarre',
		description: 'Ergänzt wird das gesangliche Talent von Tobias Ernst durch unseren Gitarristen, Matthew Fischer, der sein Können zuvor bei der offiziellen REGENBOGEN 2-Band „Delta Rock“ unter Beweis stellte. Mit seiner langjährigen Erfahrung und seinem außerordentlichen Talent entfesselt er packende Riffs auf der Bühne.',
		age: Math.floor((Date.now() - new Date(2003, 2, 28)) / 31557600000).toString() + " Jahre",
		img: require('@/assets/images/members/matthew.jpg'),
	},
	{
		id: 3,
		name: 'Joel Hoffmann',
		instrument: 'Keyboard',
		description: 'Joel Hoffmann, unser Keyboarder, fügt unseren Songs mit seinem musikalischen Feingefühl und seiner Virtuosität die perfekte harmonische Komponente hinzu.',
		age: Math.floor((Date.now() - new Date(2002, 12, 26)) / 31557600000).toString() + " Jahre",
		img: require('@/assets/images/members/joel.jpeg'),
	},
	{
		id: 4,
		name: 'Benedict Fischer',
		instrument: 'Bass',
		description: 'Unser Bassist, Benedict Fischer, unterstreicht mit seinem Spiel den Rhythmus und die Dynamik unserer Songs.',
		age: Math.floor((Date.now() - new Date(2000, 3, 19)) / 31557600000).toString() + " Jahre",
		img: require('@/assets/images/members/benedict.jpg'),
	},
	{
		id: 5,
		name: 'Nicolas Ernst',
		instrument: 'Schlagzeug',
		description: 'Nicolas Ernst, unser Schlagzeuger, legt den Puls unserer Musik fest und treibt und mit seinen präzisen, energetischen Beats den Rhythmus unserer Performance voran.',
		age: Math.floor((Date.now() - new Date(1998, 3, 30)) / 31557600000).toString() + " Jahre",
		img: require('@/assets/images/members/nicolas.png'),
	},
	{
		id: 6,
		name: 'Du!',
		instrument: 'Unser Fan',
		description: 'To be defined',
		age: "",
		img: require('@/assets/images/members/fan.jpg'),
	},
];

export default members;
