<script>
// import ThemeSwitcher from '../ThemeSwitcher';
// import HireMeModal from '../HireMeModal.vue';
import feather from 'feather-icons';
// import AppHeaderLinks from './AppHeaderLinks.vue';
// import Button from '../reusable/Button.vue';

export default {
	components: {
		// ThemeSwitcher,
		// HireMeModal,
		// AppHeaderLinks,
		// Button,
	},
	data() {
		return {
			isOpen: false,
			theme: '',
			screenWidth: 0,
			navbarOpacity: 0,
			navelementsOpactiy: 0,
			// modal: false,
			// categories: [
			// 	{
			// 		id: 1,
			// 		value: 'web',
			// 		name: 'Web Application',
			// 	},
			// 	{
			// 		id: 2,
			// 		value: 'mobile',
			// 		name: 'Mobile Application',
			// 	},
			// 	{
			// 		id: 3,
			// 		value: 'ui-ux',
			// 		name: 'UI/UX Design',
			// 	},
			// 	{
			// 		id: 4,
			// 		value: 'branding',
			// 		name: 'Branding & Anim',
			// 	},
			// ],
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	beforeMount() {
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener("resize", this.handleResize);
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
		this.screenWidth = window.innerWidth;
	},
	methods: {
		updateTheme(theme) {
			this.theme = theme;
		},
		showModal() {
			if (this.modal) {
				// Stop screen scrolling
				document
					.getElementsByTagName('html')[0]
					.classList.remove('overflow-y-hidden');
				this.modal = false;
			} else {
				document
					.getElementsByTagName('html')[0]
					.classList.add('overflow-y-hidden');
				this.modal = true;
			}
		},
		handleScroll() {
			this.updateOpacity();
		},
		handleResize() { 
			this.screenWidth = window.innerWidth;
			this.updateOpacity();
		},
		updateOpacity() {
			this.navbarOpacity = Math.min(Math.max(window.scrollY - (window.innerHeight - 2 * 96), 0) / (window.innerHeight - 96 - (window.innerHeight - 2 * 96)), 1);
			this.navelementsOpactiy = Math.min(window.scrollY / 100, 1);
		}
	},
	updated() {
		feather.replace();
	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll)
		window.removeEventListener('resize', this.handleResize)
	}
};
</script>

<template>
	<nav id="nav" class="fixed top-0 left-0 w-full h-24 z-50" :style="`background-color: rgba(255, 255, 255, ${navbarOpacity});`">
		<div :class="isOpen ? 'fixed top-0 left-0 bg-white w-full h-24' : ''">
		<div class="py-5 px-8">
			

			<!-- Header menu links and small screen hamburger menu -->
			<div class="flex justify-between items-center">
				<!-- Header logos -->
				<div class="py-2" :style="{'opacity': navelementsOpactiy}">
					<router-link to="/">
						<img v-if="theme === 'light'"
							src="@/assets/images/Logo_3D.svg"
							class="w-36"
							alt="Dark Logo"/>
						<img v-else
							src="@/assets/images/Logo_3D.svg"
							class="w-36"
							alt="Light Logo"/>
					</router-link>
				</div>

				<!-- Theme switcher small screen -->
				<!-- <theme-switcher
					:theme="theme"
					@themeChanged="updateTheme"
					class="block sm:hidden bg-ternary-light dark:bg-ternary-dark hover:bg-hover-light dark:hover:bg-hover-dark hover:shadow-sm px-2.5 py-2 rounded-lg"
				/> -->

				<!-- Small screen hamburger menu -->
				<!-- <div class="sm:hidden">
					<button
						@click="isOpen = !isOpen"
						type="button"
						class="focus:outline-none"
						aria-label="Hamburger Menu"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							class="h-7 w-7 fill-current text-secondary-dark dark:text-ternary-light"
						>
							<path
								v-if="isOpen"
								fill-rule="evenodd"
								d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
								clip-rule="evenodd"
							></path>
							<path
								v-if="!isOpen"
								fill-rule="evenodd"
								d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
							></path>
						</svg>
					</button>
				</div>  -->

				<!-- Header links -->
				<!-- <AppHeaderLinks :showModal="showModal" :isOpen="isOpen"/> -->
			</div> 

			

			<!-- Header right section buttons -->
			<!-- <div
				class="hidden sm:flex justify-between items-center flex-col md:flex-row"
			> -->
				<!-- Hire me button -->
				<!-- <div class="hidden md:block">
					<Button
						title="Hire Me"
						class="text-md font-general-medium bg-indigo-500 hover:bg-indigo-600 text-white shadow-sm rounded-md px-5 py-2.5 duration-300"
						@click="showModal()"
						aria-label="Hire Me Button"
					/>
				</div> -->

				<!-- Theme switcher large screen -->
				<!-- <theme-switcher
					:theme="theme"
					@themeChanged="updateTheme"
					class="ml-8 bg-primary-light dark:bg-ternary-dark px-3 py-2 shadow-sm rounded-xl cursor-pointer"
				/>
			</div> --> 
		</div>
	</div>

		<!-- Hire me modal -->
		<!-- <HireMeModal
			:showModal="showModal"
			:modal="modal"
			:categories="categories"
			aria-modal="Hire Me Modal"
		/> -->
	</nav>
</template>

<style scoped>
#nav a.router-link-exact-active {
	@apply text-indigo-700;
	@apply dark:text-indigo-400;
	@apply font-medium;
}
</style>
