<script>
import EventsUpcomingSingle from './EventsUpcomingSingle.vue';

export default {
    components: { EventsUpcomingSingle },
    data() {
        return {
            events: []
        }
    },
    methods: {
        async getCalendarEvents() {
            const timeMin = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString();
            const apiKey = "AIzaSyBG0Gd2qWwPe8K9bQXIfLeY7KlurU5dub8";
            this.events = await fetch(`https://www.googleapis.com/calendar/v3/calendars/mail%40revived-music.de/events?key=${apiKey}&timeMin=${timeMin}`)
                .then(response => response.json())
                .then(response => response.items.filter(entry => entry.visibility === undefined || entry.visibility != "private"))
                .then(items => items?.map((item, index) => ({
                    id: index, 
                    name: item.summary,
                    slogan: item.description === undefined ? '' : item.description,
                    location: item.location === undefined ? '' : item.location,
                    date: Date.parse(item.start.dateTime === undefined ? item.start.date : item.start.dateTime), 
                    timeundefined: item.start.dateTime === undefined
                })))
                .then(items => items.sort((a, b) => a.date - b.date));
                console.log(this.events)
        }
    },
    mounted() {
        this.getCalendarEvents();
    },
};
</script>

<template>
    <div class="text-center">
        <p class="font-general-semibold text-2xl sm:text-4xl mb-2 text-ternary-dark dark:text-ternary-light">
            Anstehende Events
        </p>
    </div>

    <div class="mt-10 grid grid-cols-1 gap-4">
        <EventsUpcomingSingle v-for="event in this.events" :key="event.id" :event="event" />
    </div>
</template>
