<script>
import AboutMemberSingle from './AboutMemberSingle.vue';
import members from '../../data/members';

export default {
    components: { AboutMemberSingle },
    data: () => {
        return {
            members
        };
    },
};
</script>

<template>
    <div class="text-center">
        <p class="font-general-semibold text-2xl sm:text-4xl mb-2 text-ternary-dark dark:text-ternary-light">
            Unsere Mitglieder
        </p>
    </div>

    <div class="mt-10 sm:mt-10 flex justify-center">
        <p class="sm:w-full font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg text-justify">
            Für die besondere Prägung unseres <b>REVIVED</b>-Stempels greifen wir auf ein eingespieltes Team bestehend aus fünf
            einzigartigen Persönlichkeiten aus Hockenheim zurück.
        </p>
    </div>

    <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
        <AboutMemberSingle v-for="member in members" :key="member.id" :member="member" />
    </div>
</template>
