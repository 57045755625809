export const socialLinks = [
	{
		id: 1,
		name: 'Instagram',
		icon: 'instagram',
		url: 'https://www.instagram.com/revived.music/',
	},
	{
		id: 2,
		name: 'Facebook',
		icon: 'facebook',
		url: 'https://www.facebook.com/revivedmusic.de/',
	},
	{
		id: 3,
		name: 'YouTube',
		icon: 'youtube',
		url: 'https://www.youtube.com/@revived-music',
	},
	// TODO: WhatsApp Icon einfügen
];
