<script>
import EventsUpcoming from '@/components/events/EventsUpcoming';
import EventsReferences from '@/components/events/EventsReferences';

export default {
	components: {
		EventsUpcoming,
		EventsReferences,
	}
};
</script>

<template>
	<div class="text-center pt-24">
		<p class="font-general-semibold text-3xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light">
			Events
		</p>
	</div>

	<div class="mt-10 sm:mt-10 flex justify-center">
		<p class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg text-justify">
			Mit <b>REVIVED</b> seid ihr bei jedem Anlass in den besten Händen! Wir sind die ideale Band für Live , Privat- und
			Firmen-Veranstaltungen. Ob bei Musikfestivals, Konzerten und Stadtfesten, Hochzeiten, Geburtstagsfeiern und
			Jubiläen oder Betriebsfeiern, Galaabenden und Produktpräsentationen – wir sorgen für eine dynamische und
			abwechslungsreiche Darbietung, die das Publikum aller Altersgruppen anspricht, mitreißt und für eine
			unterhaltsame Atmosphäre sorgt.
		</p>
	</div>


	<div class="pt-12">
		<EventsUpcoming />
	</div>

	<div class="pt-12">
		<EventsReferences />
</div></template>
