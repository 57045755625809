<script>
import feather from 'feather-icons';
import { socialLinks } from '../../data/socialLinks';
// import EventsReferencesGallery from '@/components/events/EventsReferencesGallery';

export default {
    // components: { EventsReferencesGallery }, 
    data: () => {
        return {
            theme: '',
            socials: socialLinks
        };
    },
    created() {
        this.theme = localStorage.getItem('theme') || 'light';
    },
    mounted() {
        feather.replace();
    },
    updated() {
        feather.replace();
    },
    methods: {},
};
</script>

<template>
    <div class="text-center">
        <p class="font-general-semibold text-2xl sm:text-4xl mb-2 text-ternary-dark dark:text-ternary-light">
            Referenzen
        </p>
    </div>

    <div class="mt-10 flex justify-center">
        <p class="sm:w-full font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg text-justify">
            Seit unserer Gründung im Jahr 2022 können wir eine bemerkenswerte Anzahl an Auftritten vorweisen. Neben
            zahlreichen privaten Veranstaltungen wie Hochzeiten und Weihnachtsfeiern haben wir unser Können auch auf
            renommierten lokalen Events wie der Kerwe in Laudenbach und Brühl, der <a class="hover:underline cursor-pointer"
                href="https://www.schwetzinger-zeitung.de/fotos_fotostrecke,-fotostrecke-der-skatepark-hockenheim-feiert-eroeffnung-_mediagalid,41837.html"
                target="_blank">Eröffnung des Skateparks</a> in Hockenheim
            oder dem <a class="hover:underline cursor-pointer"
                href="https://www.mannheimer-morgen.de/fotos_fotostrecke,-fotostrecke-hockenheim-tanzschule-feil-feiert-mit-workshopmarathon-_mediagalid,40616.html"
                target="_blank">30-jährigen Jubiläum der Tanzschule Feil</a> unter Beweis gestellt. Der nachhallende Applaus
            und die
            begeisterten Gesichter von mehr als 600 Zuschauern auf dem Abiball des Johann-Sebastian-Bach-Gymnasiums bleiben
            uns in denkwürdiger Erinnerung.

            <br /><br />
            Mit unserer starken Online-Präsenz erreichen wir ein breites Publikum. Folgt uns auf Instagram, Facebook und
            YouTube und besuchen Sie unsere Website unter <a class="hover:underline cursor-pointer" href="https://www.revived-music.de"
                target="_blank">www.revived-music.de</a>, um regelmäßige Updates, Ausschnitte von
            Auftritten und weiteres Promotionsmaterial zu erhalten.

        </p>
    </div>

    <!-- <div class="mt-10 sm:mt-10 flex justify-center">
       <EventsReferencesGallery/>
    </div> -->

    <div class="w-full h-full z-20 flex flex-col justify-center">
        <ul class="flex gap-4 sm:gap-8 justify-center">
            <a v-for="social in socials" :key="social.id" :href="social.url" target="__blank"
                class="hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg dark:bg-ternary-dark hover:bg-gray-100 p-4 duration-500">
            <i :data-feather="social.icon" class="w-8 sm:w-12 h-8 sm:h-12"></i>
        </a>
    </ul>
</div></template>
