<script>
export default {};
</script>

<template>
    <div class="text-center">
        <p class="font-general-semibold text-2xl sm:text-4xl mb-2 text-ternary-dark dark:text-ternary-light">
            Unsere Musik
        </p>
    </div>

    <div class="mt-10 sm:mt-10 flex justify-center">
        <p class="sm:w-full font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg text-justify">
            Unser Musikstil ist ein pulsierender Mix verschiedener Rock- und Pop-Genres, unser Repertoire dementsprechend
            vielfältig. Die
            rockigen Riffs von Guns N' Roses' „Knockin' on Heaven's Door“, das mitreißende Disco-Feeling von ABBA&apos;s
            „Gimme! Gimme! Gimme!“, der unvergessliche Pop- Sound von Roxette&apos;s „The Look“ und die punkige Energie von
            Green
            Day&apos;s „Basket Case“ sind nur einige Beispiele für die musikalische Vielfalt, die wir auf der Bühne
            präsentieren. Dabei
            trägt jeder Song, den wir spielen, unseren einzigartigen <b>REVIVED</b>-Stempel, der den Charme und die Authentizität
            des Originals bewahrt, gleichzeitig aber eine frische und dynamische Interpretation bietet.
        </p>
    </div>

    <iframe style="border-radius:12px"
        src="https://open.spotify.com/embed/playlist/06LYKtvmttS1onhF6IWJgQ?utm_source=generator&theme=0" width="100%"
        height="500" frameBorder="0" allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
</template>
